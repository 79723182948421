import React from 'react';

import BreadcrumbAndGoBack from '../BreadcrumbAndGoBack';
import CommonHeader from '../CommonHeader';
import styles from '../style.module.scss';

const Symbol52WeekLowReversal = (props) => {
  const {
    reportType,
    reportSubType,
    goBack,
    isLoading,
    currentSearchCriteria,
    reportTitle,
    fetchReportName,
    fetchingName,
    breadcrumb,
    isFilterEmpty,
    handleFilterValueClick,
    handleReset,
    history,
    note,
    searchReportTitle,
  } = props;
  return (
    <>
      <BreadcrumbAndGoBack
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        history={history}
      />
      <div className={styles.symbolSearchContentBox}>
        <div
          className={styles.titleContainer}
          style={{
            marginBottom: '12px',
          }}
        >
          <div
            className={styles.titleContent}
            style={{
              gap: '12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <h3
                style={{
                  lineHeight: '20px',
                }}
              >
                {searchReportTitle}
              </h3>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                  color: 'var(--whitecolor)',
                }}
              >
                <span style={{ color: 'red' }}>
                  {note[reportSubType || reportType]?.length > 2 ? '*' : ''}
                </span>{' '}
                {note[reportSubType || reportType]}
              </p>
            </div>
          </div>
        </div>

        {isFilterEmpty.length > 0 && (
          <div className={styles.filterValueBox}>
            <CommonHeader
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
              reportCode={reportSubType}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Symbol52WeekLowReversal;
