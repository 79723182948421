import React, { useEffect } from 'react';

import './Notification.css';

const Notification = ({ message, onClose }) => {
  useEffect(() => {
    // Automatically close notification after 5 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 20000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [onClose]);

  return (
    <div className="notification">
      <p>{message}</p>
      <button className="close-btn" onClick={onClose}>
        X
      </button>
    </div>
  );
};

export default Notification;
