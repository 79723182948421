import React, { useEffect } from 'react';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import PubSub from '@aws-amplify/pubsub';
// import { API, graphqlOperation } from 'aws-amplify';
import {
  Authenticator,
  SignIn as AwsSignIn,
  Greetings,
} from 'aws-amplify-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import CustomChatbot from './components/chatbot/CustomChatbot';
import authenticatorConfig from './config/authenticatorConfig';
import { getCustomerSubscriptionInfo } from './graphql';
// import { notifications } from './graphql/subscriptions';
import configureStore from './redux/configureStore';
import { setUserProfile } from './redux/reducers/user';
import Routes from './Routes';
import './App.css';
import { useTheme } from './ThemeContext';
import { googleAnalyticsUpdateUserInfo } from './utils/AnalyticsHelper';
import NotificationContainer from './views/Notifications/NotificationContainer';
const { persistor, store } = configureStore();

// Amplify.configure(authenticatorConfig);
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

PubSub.configure(authenticatorConfig);

const App: React.FC = (Props) => {
  const { setTheme } = useTheme();
  // const [text, setText] = useState<string>('');
  // const [appText, setText] = useState<string>('');

  const loadProfile = async (authState: string): Promise<any> => {
    let promises: Array<any> = [];
    if (authState === 'signedIn') {
      googleAnalyticsUpdateUserInfo();
      // subscription testing
      // const subscription = API.graphql(
      //   graphqlOperation(notifications, {
      //     email: 'vishal.0694@gmail.com',
      //     body: 'Check Out Our New Refer And Earn Program',
      //   }),
      // );
      // subscription.subscribe({
      //   next: (value) => setText(value),
      //   error: (error) => console.warn(error),
      // });
      const user = await getCustomerSubscriptionInfo();
      promises.concat([store.dispatch(setUserProfile(user))]);
    }
    return Promise.all(promises);
  };
  // Sentry.init({
  //   dsn: process.env.REACT_APP_SENTRY,
  //   autoSessionTracking: true,
  //   integrations: [new Integrations.BrowserTracing()],
  //   environment: process.env.REACT_APP_ENVIRONMENT,

  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0,
  // });
  const defaultTheme = localStorage.getItem('theme') || 'dark';

  useEffect(() => {
    setTheme(defaultTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Authenticator
          hide={[AwsSignIn, Greetings]}
          amplifyConfig={authenticatorConfig}
          onStateChange={loadProfile}
        >
          <Routes />
          <div style={{ bottom: '100px' }}>
            <CustomChatbot />
          </div>
          <NotificationContainer />
        </Authenticator>
      </PersistGate>
    </Provider>
  );
};

export default App;
