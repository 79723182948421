import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';

import SaveReport from './SaveReport';
import styles from './style.module.scss';

const BreadcrumbAndGoBack = (props) => {
  const {
    reportType,
    reportSubType,
    goBack,
    isLoading,
    currentSearchCriteria,
    reportTitle,
    fetchReportName,
    fetchingName,
    breadcrumb,
    history,
  } = props;
  return (
    <>
      <div className={styles.vGoBackSaveBar}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <p
            onClick={() =>
              get(history, 'location.state.prevPage', '') === 'search'
                ? history.push({
                    pathname: '/search',
                    state: {
                      type: reportType,
                      subType: reportSubType,
                      prevPage: 'searchReport',
                    },
                  })
                : goBack()
            }
            className={styles.vGoBackBtnBlock}
            style={{
              margin: '0px',
            }}
          >
            {history.length > 2 && (
              <span className={styles.vGoBackBtn}>
                <ArrowLeftOutlined /> Go Back
              </span>
            )}
          </p>
          <Breadcrumb>
            {breadcrumb.map((item) => (
              <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        {!isLoading && (
          <div className={styles.SaveReportBoxNew2}>
            <SaveReport
              searchCriteria={currentSearchCriteria}
              reportType={reportType}
              reportSubType={reportSubType}
              reportTitle={reportTitle}
              fetchReportName={fetchReportName}
              fetchingName={fetchingName}
            />
          </div>
        )}
        {isLoading && <div className={styles.SaveReportBoxNew2}></div>}
      </div>
    </>
  );
};

export default BreadcrumbAndGoBack;
