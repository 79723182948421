import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Dot,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { DraggableModal } from '../../components';
import { COLORS } from './SearchReport.constants';

const DiamondReportGraphicalRep = (props) => {
  const {
    showGraph,
    setShowGraph,
    countData,
    itemsPerPage,
    handlePrev,
    currentPage,
    handleNext,
    reportSubType,
    getDisplayableQtr,
    getPreviousQtr,
    sharesMinMax,
    transformedObject,
    countMinMax,
    selectedQuarter,
    avgMinMax,
    priceMinMax,
    CustomYAxisTick,
    CustomCursor,
    processData,
    CustomTooltip,
    CustomTooltipForPie,
    getPageData,
    quarterBetweenDateTitle,
    priceData,
    formatLargeNumbers,
    volumeMinMax,
    aivMinMax,
    CustomLegend,
    renderLabel,
    sharesData,
    avgData,
    volumeData,
    aivData,
  } = props;
  return (
    <DraggableModal
      visible={showGraph}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Diamonds Graphical Representation Report</span>
        </div>
      }
      width={1100}
      onCancel={() => setShowGraph(false)}
      footer={null}
    >
      <div id="modal-content">
        {/* <h3
          style={{
            textAlign: 'center',
            color: 'var(--whitecolor)',
            marginBottom: '16px',
            fontSize: '20px',
          }}
        >
          Diamonds Graphical Representation Report
        </h3> */}
        {countData.length > itemsPerPage && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <button
              onClick={handlePrev}
              disabled={currentPage === 0}
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              Back
            </button>
            <button
              onClick={handleNext}
              disabled={(currentPage + 1) * itemsPerPage >= countData.length}
              style={{ padding: '5px 10px', cursor: 'pointer' }}
            >
              Next
            </button>
          </div>
        )}
        {/* Shares Comparison Bar Chart for All Symbols */}
        <div
          style={{
            // transform: 'scale(0.5)',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            hidden={reportSubType === 'THINKSABIO_DIAMONDS_PV'}
            style={{ width: '100%' }}
          >
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              Institution's Shares Comparison Over{' '}
              {getDisplayableQtr(selectedQuarter)} to{' '}
              {getPreviousQtr(selectedQuarter)}
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={getPageData(sharesData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[sharesMinMax.min, sharesMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar
                  dataKey={transformedObject['lastQtr2Shares']}
                  fill="#8884d8"
                  barSize={20}
                ></Bar>
                <Bar
                  dataKey={transformedObject['lastQtr1Shares']}
                  fill="#82ca9d"
                  barSize={20}
                ></Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Count Comparison Bar Chart for All Symbols */}
        <div
          hidden={reportSubType === 'THINKSABIO_DIAMONDS_PV'}
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              Institution's Count Comparison Over{' '}
              {getDisplayableQtr(selectedQuarter)} to{' '}
              {getPreviousQtr(selectedQuarter)}
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={getPageData(countData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[countMinMax.min, countMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar
                  dataKey={transformedObject['lastQtr2Count']}
                  fill="#8884d8"
                  barSize={20}
                />
                <Bar
                  dataKey={transformedObject['lastQtr1Count']}
                  fill="#82ca9d"
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* Avg Price Comparison Bar Chart */}
        <div
          hidden={reportSubType === 'THINKSABIO_DIAMONDS'}
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              Institution's Avg Price Comparison Over{' '}
              {getDisplayableQtr(selectedQuarter)} to{' '}
              {getPreviousQtr(selectedQuarter)}
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={getPageData(avgData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[avgMinMax.min, avgMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  // formatter={(value: any) => formatLargeNumbers(value)}
                  content={<CustomTooltip />}
                  cursor={<CustomCursor />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar
                  dataKey={transformedObject['lastQtr2Avg']}
                  fill="#8884d8"
                  barSize={20}
                />
                <Bar
                  dataKey={transformedObject['lastQtr1Avg']}
                  fill="#82ca9d"
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Volume Comparison Bar Chart for All Symbols */}
        <div
          hidden={reportSubType === 'THINKSABIO_DIAMONDS'}
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              Institution's Volume Comparison Over{' '}
              {getDisplayableQtr(selectedQuarter)} to{' '}
              {getPreviousQtr(selectedQuarter)}
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={getPageData(volumeData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[volumeMinMax.min, volumeMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar
                  dataKey={transformedObject['lastQtr2Volume']}
                  fill="#8884d8"
                  barSize={20}
                />
                <Bar
                  dataKey={transformedObject['lastQtr1Volume']}
                  fill="#82ca9d"
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              {transformedObject[quarterBetweenDateTitle]} vs Last sale = %
              Returns
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={getPageData(priceData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[priceMinMax.min, priceMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />

                {/* Line for Price on Feb 14 */}
                <Line
                  type="monotone"
                  dataKey={quarterBetweenDateTitle}
                  stroke="#8884d8"
                  strokeWidth={0.5}
                  opacity={5}
                  dot={<Dot r={1} />}
                  name={transformedObject[quarterBetweenDateTitle]}
                />

                {/* Line for Last Sale */}
                <Line
                  type="monotone"
                  dataKey="Last Sale"
                  stroke="#28a745"
                  strokeWidth={0.5}
                  opacity={5}
                  dot={<Dot r={1} />}
                  name="Last Sale"
                />
                {/* Line for % Returns */}
                <Line
                  type="monotone"
                  dataKey="% Returns"
                  stroke="#ff8c00"
                  strokeWidth={0.5}
                  opacity={5}
                  dot={<Dot r={1} />}
                  name="% Returns"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* Invested Value Comparison Bar Chart */}
        <div
          hidden={reportSubType === 'THINKSABIO_DIAMONDS_PV'}
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              {transformedObject['lastQtr1ApproxInvestedValue']}
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={getPageData(aivData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[aivMinMax.min, aivMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                />
                <Legend
                  layout="horizontal"
                  align="center"
                  content={<CustomLegend />}
                />
                <Bar
                  dataKey={transformedObject['lastQtr1ApproxInvestedValue']}
                  fill="#8884d8"
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* 52 Wk Low Bar Chart */}
        <div
          hidden={reportSubType === 'THINKSABIO_DIAMONDS'}
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              52 Week Low
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={getPageData(priceData).map((stock) => ({
                  name: stock.name,
                  '52 W Low': stock['52 Wk Low'],
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[priceMinMax.min, priceMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  // formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                  content={<CustomTooltip />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar dataKey="52 W Low" fill="#8884d8" barSize={20} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* 52 Wk High Bar Chart */}
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              52 Week High
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                data={getPageData(priceData).map((stock) => ({
                  name: stock.name,
                  '52 W High': stock['52 Wk High'],
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[priceMinMax.min, priceMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  // formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                  content={<CustomTooltip />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar dataKey="52 W High" fill="#8884d8" barSize={20} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Average Price Comparison Bar Chart */}
        <div
          hidden={true}
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              Average Price Comparison
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={getPageData(avgData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tick={{
                    textAnchor: 'middle',
                    fill: '#1abd50',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                />
                <YAxis
                  domain={[avgMinMax.min, avgMinMax.max]}
                  tick={<CustomYAxisTick />}
                  tickMargin={20}
                />
                <Tooltip
                  // formatter={(value: any) => formatLargeNumbers(value)}
                  cursor={<CustomCursor />}
                  content={<CustomTooltip />}
                />
                <Legend layout="horizontal" align="center" />
                <Bar
                  dataKey={transformedObject['lastQtr2Avg']}
                  fill="#8884d8"
                  barSize={20}
                />
                <Bar
                  dataKey={transformedObject['lastQtr1Avg']}
                  fill="#82ca9d"
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* Sector Pie Chart */}
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div id="pie-chart" style={{ width: '100%' }}>
            <h4
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
                fontSize: '16px',
              }}
            >
              Sector Allocation
            </h4>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={processData}
                  dataKey="count"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={renderLabel} // Custom label rendering
                >
                  {processData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltipForPie />} />

                {/*<Legend />*/}
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default DiamondReportGraphicalRep;
