import React, { useState, useEffect, useCallback } from 'react';

import { Input } from 'antd';
import { debounce, get } from 'lodash';
import BounceLoader from 'react-spinners/BounceLoader';

import { Table, Pagination } from '../../../components';
import { fetchPopularCompanies, getUniversityDropdown } from '../../../graphql';
import { useTheme } from '../../../ThemeContext';
import {
  PopularStockData,
  errorHandler,
  formatColumn,
  green,
} from '../../../utils';
import styles from './style.module.scss';

export const arrangeColumns = (columns: Object) => {
  const formattedColumns = Object.keys(columns).map((col, index) => ({
    key: col,
    name: col,
    value: columns[col],
    // visible: findColumnVisibility(col),
    index: col === 'symbol' ? 0 : index + 1,
    sort: col !== 'status' || col !== 'specialStatus' || col !== 'changeStatus',
  }));
  return formattedColumns;
};

const PopularStock: React.FC<Props> = ({ history, match }) => {
  const { theme } = useTheme();
  const { params } = match;
  const { type, stockCategory } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [sortDetail, setSortDetail] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [searchText, setSearchText] = useState();
  const [category, setCategory] = useState(stockCategory || 'ENERGY');
  const [tableColumns, setTableColumns] = useState([]);
  const [categories, setCategories] = useState([]);

  const { title } = PopularStockData[type];
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      page: pageNo,
      size: pageSize,
      searchText: searchText,
      category: category,
      symbol: [],
      sortBy: get(sortDetail, '0.sortBy', ''),
      sortOrder: get(sortDetail, '0.sortOrder', ''),
    };
    const promises = [fetchPopularCompanies(payload), getUniversityDropdown()];
    Promise.all(promises)
      .then((res) => {
        setTableData(res[0]);
        const { columns = [] } = res[0];
        setTableColumns(arrangeColumns(columns));
        setCategories(res[1]);
        console.log(res[1][0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [category, pageNo, pageSize, searchText, sortDetail]);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };

  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };
  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );
  const onTextChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };

  const { data, total } = tableData;

  const handleSort = (sortBy, sortOrder, multi) => {
    if (sortOrder) {
      setSortDetail([
        {
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      ]);
    } else {
      setSortDetail([]);
    }
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const changeCategory = (val) => {
    setCategory(val);
    // setSearchText('');

    // http://localhost:3000/university/stocks/popular-stock
    const path = `/university/${type}/popular-stock/${val}`;

    history.push(path);
  };

  return (
    <div className={styles.popularPage}>
      <div className={styles.popularHeaderView}>
        <h1>{title}</h1>
        <div className={styles.expertBtnSection}>
          <button className={styles.back} onClick={() => history.goBack()}>
            BACK
          </button>
          {categories.map(({ name, value }) => (
            <button
              className={styles.category}
              style={{
                backgroundColor: category === name ? '#149dcc' : '#232323',
              }}
              onClick={() => changeCategory(name)}
              key={name}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.dataSection}>
        <div className={styles.header}>
          <h3>{`${category ? category : stockCategory} STOCKS`}</h3>
        </div>
        <div>
          <p
            style={{
              color: 'var(--whitecolor)',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <span style={{ color: 'red' }}>*</span>The Default Sort is based on
            the Market value
          </p>
        </div>
        {!isLoading || (data || []).length > 0 ? (
          <>
            <div className={styles.vReportSearchHead}>
              <Input
                placeholder={`search by symbol...`}
                onChange={onTextChange}
                className={styles.VdailySearch}
                allowClear
              />
            </div>
            <Table
              loading={isLoading}
              // scroll={{ y: y }}
              columns={formatColumn(
                tableColumns,
                true,
                sortDetail,
                history,
                false,
                null,
                null,
                null,
                null,
                theme,
                // formatTableData2(get(trendingData, 'data', [])),
              )}
              // columns={columns}
              dataSource={data}
              pagination={false}
              size="small"
              bordered={true}
              // rowClassName={styles.tableHeader}
              className={styles.TabularData}
              showSorterTooltip={false}
              onChange={onTableChange}
              id="allPicksId"
            />
            <div style={{ width: '100%' }}>
              <Pagination
                pageSize={pageSize}
                total={total}
                pageNo={pageNo}
                onChange={onPaginationChange}
                loading={isLoading}
                showPagination={true}
              />
            </div>
          </>
        ) : (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PopularStock;
