import React from 'react';

import { get } from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import {
  getSearchSymbolCompareData,
  commonSearchQuery,
  getOptionBySymbols,
  getSearchOpenSymbolData,
  getSearchSymbolData,
} from '../../graphql';
import { currencyFormat, formatColumn, getTimeRange } from '../../utils';
import { formatDataRow, formatForecastedData } from './FormatTableColumns';
import {
  compare1,
  compare2,
  compare3,
  compare4,
  compare5,
  compare6,
  compare7,
  arrangeColumns,
  getSummaryFilteredData,
} from './SearchReport.constants';
import {
  Symbol52WeekLowReversal,
  DiamondReportHeader,
  SimpleHeader,
} from './SearchReportHeaders/';

const filterColumns = (cols, compareData) => {
  return arrangeColumns(cols).filter((col) => compareData.includes(col.key));
};

const getTotal = (data) => {
  let total = 0;
  for (let i = 0; i < data.data.length; i++) {
    total = total + data.data[i]['approxInvestedValue'];
  }
  return total;
};

export const getGraphQlQueryByType = (
  reportType: string,
  reportSubType: string,
  filterData: {},
  pageNo: number,
  pageSize: number,
  selectedQuarter: string,
  selectedNoOfQtrs: number,
  noOfWeeks: number,
  filterOptions: {},
  sortDetail: [],
  searchText: string,
  selectedDateIndex: number,
  trendingStockType: string,
  topGainersType: string,
  noOfDays: number,
  noOfWeeklyYTDWeeksList: any,
) => {
  let queryData = {
    filters: {},
    page: pageNo,
    size: pageSize,
    // sortBy: sortBy,
    // sortOrder: sortOrder,
    searchText: searchText,
    // isTop10: false,
    // cik: null,
    // quarter: null,
    type: reportType,
    subType: reportSubType,
    sort: sortDetail,
  };
  const {
    symbols,
    institutions,
    criterion,
    minPrice,
    maxPrice,
    minFiveYrGrowth,
    minInstCount,
    minInstCountChange,
    maxPossibleGrowth,
    minPossibleGrowth,
    minLastSale,
    minRank,
    maxRank,
    minOneYrGrowth,
    minOwnership,
    minYtd,
    symbol,
    maxFiveYrGrowth,
    maxInstCount,
    maxLastSale,
    maxOneYrGrowth,
    maxYtd,
    minInstSharesChange,
    insiderName,
    insiderTitle,
    isTenPercent,
  } = filterData;
  switch (reportType) {
    case 'SYMBOL':
      switch (reportSubType) {
        case 'INDIVIDUAL_STATISTICAL_REPORT':
          return {
            query: getSearchSymbolData(symbols),
            queryData: {
              type: reportType,
              subType: reportSubType,
              symbols: symbols,
            },
          };
        case 'INDIVIDUAL_STATISTICAL_OPEN_REPORT':
          return {
            query: getSearchOpenSymbolData(symbols),
            queryData: {
              type: reportType,
              subType: reportSubType,
              symbols: symbols,
            },
          };
        case 'INDIVIDUAL_STATUS_REPORT':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'PRICE_HISTORY':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
            symbols: symbols,
          };
        case 'COMPARE_SYMBOLS':
          return {
            query: getSearchSymbolCompareData(symbols),
            queryData: {
              type: reportType,
              subType: reportSubType,
              symbols: symbols,
            },
          };
        case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
          queryData.filters = {
            symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'MOVING_AVERAGES':
          queryData.filters = {
            // symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DOW_THEORY_DOWNTREND':
        case 'DOW_THEORY_UPTREND':
        case 'TS_WEEKLY_REPORT':
          queryData.filters = {
            noOfWeeks: noOfWeeks,
            ...filterOptions,
          };
          if (get(queryData, 'filters.symbols', []).length > 0) {
            delete queryData.filters.noOfWeeks;
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DOW_DAILY_PRICE_DOWNTREND':
        case 'DOW_DAILY_PRICE_UPTREND':
          queryData.filters = {
            noOfDays: noOfDays,
            ...filterOptions,
          };
          if (get(queryData, 'filters.symbols', []).length > 0) {
            delete queryData.filters.noOfDays;
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DOW_DAILY_PRICE_VOLUME_DOWNTREND':
        case 'DOW_DAILY_PRICE_VOLUME_UPTREND':
          queryData.filters = {
            ...filterOptions,
            noOfDays: noOfDays,
          };
          if (get(queryData, 'filters.symbols', []).length > 0) {
            delete queryData.filters.noOfDays;
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'TRENDING_STOCK': {
          queryData.filters = {
            date: moment()
              .tz('America/New_York')
              .add(selectedDateIndex, 'days')
              .format('YYYY-MM-DD'),
            ...filterOptions,
            trendingStockType: trendingStockType,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        }
        case 'ALL_SYMBOLS':
          queryData.filters = {
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'C52WKHL':
          queryData.filters = {
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            category: get(filterOptions, 'category', 'fifty'),
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'INSTITUTION':
      switch (reportSubType) {
        case 'INDIVIDUAL_INSTITUTION_REPORT':
          queryData.filters = {
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INSTITUTION_PERFORMANCE_REPORT':
          queryData.filters = {
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INSTITUTION_ALLOCATION_REPORT':
          queryData.filters = {
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'TOP_TWENTY_FIVE_SYMBOLS':
          queryData.filters = {
            quarter: selectedQuarter,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'COMPARE_INSTITUTIONS':
          queryData.filters = {
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
          queryData.filters = {
            symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
          queryData.filters = {
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INCREASED':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'NO_CHANGE':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DECREASED':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'NEWLY_BOUGHT':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'SOLD_OFF':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'TOP_10_HEDGE':
      switch (reportSubType) {
        case 'INDIVIDUAL_INSTITUTION_REPORT':
          queryData.filters = {
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'COMPARE_INSTITUTIONS':
          queryData.filters = {
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
          queryData.filters = {
            symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
          queryData.filters = {
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INCREASED':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'NO_CHANGE':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DECREASED':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            noOfQuartersForShares: selectedNoOfQtrs,
            noOfQuartersForCounts: selectedNoOfQtrs,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'NEWLY_BOUGHT':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'SOLD_OFF':
          queryData.filters = {
            criterion: criterion.toUpperCase(),
            quarter: selectedQuarter,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'SEC_13_DG':
      switch (reportSubType) {
        case 'DAILY_REPORT':
          queryData.filters = {
            date: moment()
              .tz('America/New_York')
              .add(selectedDateIndex, 'days')
              .format('YYYY-MM-DD'),
            ...filterOptions,
          };

          if (minOwnership) {
            queryData.filters.ownership = {
              min: parseInt(minOwnership),
            };
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'SYMBOL_SUMMARY':
          queryData.filters = {
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DETAIL_SUMMARY_REPORT':
          queryData.filters = {
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            ...filterOptions,
          };
          if (minOwnership) {
            queryData.filters.ownership = {
              min: parseInt(minOwnership),
            };
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'BY_APPROX_TXN_VAL':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'MUTUAL_FUNDS':
      switch (reportSubType) {
        case 'DAILY_REPORT_MUTUAL_FUNDS':
          queryData.filters = {
            date: moment()
              .tz('America/New_York')
              .add(selectedDateIndex, 'days')
              .format('YYYY-MM-DD'),
            ...filterOptions,
          };

          if (minOwnership) {
            queryData.filters.ownership = {
              min: parseInt(minOwnership),
            };
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'SYMBOL_SUMMARY_MUTUAL_FUNDS':
          queryData.filters = {
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'BY_FUND_SYMBOL':
          queryData.filters = {
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'BY_FUND_NAME':
          queryData.filters = {
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            ...filterOptions,
          };
          if (minOwnership) {
            queryData.filters.ownership = {
              min: parseInt(minOwnership),
            };
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'SECTOR':
      switch (reportSubType) {
        case 'ALL_INSTITUTIONS':
          queryData.filters = {
            // multiSector: multiSector,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'TOP_10_HEDGE':
          queryData.filters = {
            // multiSector: multiSector,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'ALLOCATIONS':
          queryData.filters = {
            category: get(filterOptions, 'category', 'All Institutions'),
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'SECTOR_TRENDING':
          queryData.filters = {
            // multiSector: multiSector,
            ...filterOptions,
            endDate: filterOptions.endDate
              ? moment(filterOptions.endDate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
            startDate: filterOptions.startDate
              ? moment(filterOptions.startDate).format('YYYY-MM-DD')
              : moment().subtract(1, 'years').format('YYYY-MM-DD'),
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'SEC_INSIDERS':
      switch (reportSubType) {
        case 'INSIDER_DAILY_REPORT':
          queryData.filters = {
            // insiderName: insiderName,
            date: moment()
              .tz('America/New_York')
              .add(selectedDateIndex, 'days')
              .format('YYYY-MM-DD'),
            // symbols: typeof symbols === 'string' ? [symbols] : symbols,
            type: get(filterOptions, 'type', ''),
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INSIDER_ALL_CUMULATIVE_REPORT':
          queryData.filters = {
            insiderName: insiderName,
            insiderTitle: insiderTitle,
            isTenPercent: isTenPercent,
            // secFrom: secFrom,
            // secTo: secTo,

            // symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ...filterOptions,
            type: get(filterOptions, 'type', ''),
            endDate: filterOptions.endDate
              ? moment(filterOptions.endDate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
            startDate: filterOptions.startDate
              ? moment(filterOptions.startDate).format('YYYY-MM-DD')
              : moment().subtract(1, 'years').format('YYYY-MM-DD'),
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INSIDER_DETAIL_SUMMARY_REPORT':
          queryData.filters = {
            insiderName: insiderName,
            insiderTitle: insiderTitle,
            isTenPercent: isTenPercent,
            // secFrom: secFrom,
            // secTo: secTo,

            // symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ...filterOptions,
            type: get(filterOptions, 'type', ''),
            endDate: filterOptions.endDate
              ? moment(filterOptions.endDate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
            startDate: filterOptions.startDate
              ? moment(filterOptions.startDate).format('YYYY-MM-DD')
              : moment().subtract(1, 'years').format('YYYY-MM-DD'),
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'INSIDER_SYMBOL_SUMMARY':
          queryData.filters = {
            // insiderName: insiderName,
            // secFrom: secFrom,
            // secTo: secTo,
            // startDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            // endDate: moment().format('YYYY-MM-DD'),
            // symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ...filterOptions,
            endDate: filterOptions.endDate
              ? moment(filterOptions.endDate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
            startDate: filterOptions.startDate
              ? moment(filterOptions.startDate).format('YYYY-MM-DD')
              : moment().subtract(1, 'years').format('YYYY-MM-DD'),
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'OPTION':
      switch (reportSubType) {
        case 'OPTION_ALL_INSTITUTION':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'OPTION_BY_SYMBOL':
          return {
            query: getOptionBySymbols(symbols),
            queryData: {
              type: reportType,
              subType: reportSubType,
              symbols: symbols,
            },
          };
        case 'OPTION_BY_INSTITUTION':
          queryData.filters = {
            ciks:
              typeof institutions === 'string' ? [institutions] : institutions,
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }
    case 'PRICE':
      queryData.filters = {
        minPrice: minPrice,
        maxPrice: maxPrice,
        quarter: selectedQuarter,
        noOfQuartersForShares: selectedNoOfQtrs,
        noOfQuartersForCounts: selectedNoOfQtrs,
        ...filterOptions,
      };
      return {
        query: commonSearchQuery(queryData),
        queryData: queryData,
      };
    case 'THINKSABIO_DIAMONDS':
      queryData.filters = {
        quarter: selectedQuarter,
        noOfQuartersForShares: selectedNoOfQtrs,
        noOfQuartersForCounts: selectedNoOfQtrs,
        ...filterOptions,
      };
      return {
        query: commonSearchQuery(queryData),
        queryData: queryData,
      };

    case 'MARK_MINERVINI_STRATEGY':
      queryData.filters = {
        ...filterOptions,
        date: filterOptions.queryDate
          ? moment(filterOptions.queryDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      };
      delete queryData.filters.queryDate;
      return {
        query: commonSearchQuery(queryData),
        queryData: queryData,
      };

    // case 'TOP_GAINERS':
    //   queryData.filters = {
    //     ...filterOptions,
    //     endDate: filterOptions.endDate
    //       ? moment(filterOptions.endDate).format('YYYY-MM-DD')
    //       :  moment().format('YYYY-MM-DD'), //moment(getTimeRange('1D').end).format('YYYY-MM-DD'),
    //       startDate: filterOptions.startDate
    //       ? moment(filterOptions.startDate).format('YYYY-MM-DD')
    //       : moment().subtract(1, 'day').format('YYYY-MM-DD'), //moment(getTimeRange('1D').start).format('YYYY-MM-DD'),
    //     trendingStockType: topGainersType,
    //   };
    //   // queryData}
    //   return {
    //     query: commonSearchQuery(queryData),
    //     queryData: queryData,
    //   };

    case 'INTERNAL_REPORTS':
      switch (reportSubType) {
        case 'INSIDER_ALL_CUMULATIVE_REPORT':
          queryData.filters = {
            insiderName: insiderName,
            insiderTitle: insiderTitle,
            isTenPercent: isTenPercent,
            // secFrom: secFrom,
            // secTo: secTo,

            // symbols: typeof symbols === 'string' ? [symbols] : symbols,
            ...filterOptions,
            type: get(filterOptions, 'type', ''),
            endDate: filterOptions.endDate
              ? moment(filterOptions.endDate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
            startDate: filterOptions.startDate
              ? moment(filterOptions.startDate).format('YYYY-MM-DD')
              : moment().subtract(1, 'years').format('YYYY-MM-DD'),
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'TOP_GAINERS':
          queryData.filters = {
            ...filterOptions,
            endDate: filterOptions.endDate
              ? moment(filterOptions.endDate).format('YYYY-MM-DD')
              : moment(getTimeRange('1D', true).end).format('YYYY-MM-DD'),
            startDate: filterOptions.startDate
              ? moment(filterOptions.startDate).format('YYYY-MM-DD')
              : moment(getTimeRange('1D', true).start).format('YYYY-MM-DD'),
            trendingStockType: topGainersType,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'WEEKLY_GAINS_REPORT':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'TELEGRAM_OPTIONS':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DOW_HIGH_LEVEL_REPORT':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'DOW_DOWN_TREND_REPORT':
          queryData.filters = {
            ...filterOptions,
          };
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        case 'WEEKLY_YTD':
          queryData.filters = {
            ...filterOptions,
          };
          if (!get(queryData, 'filters.noOfWeeklyYTDWeeks')) {
            queryData.filters.noOfWeeklyYTDWeeks = get(
              noOfWeeklyYTDWeeksList,
              'Increased.0.value',
              null,
            );
          }
          if (queryData.filters.noOfWeeklyYTDWeeks === 'ALL') {
            queryData.filters.noOfWeeklyYTDWeeks = null;
          }
          if (get(queryData, 'filters.symbols', []).length > 0) {
            queryData.filters.noOfWeeklyYTDWeeks = null;
          }
          if (get(queryData, 'searchText', '')) {
            queryData.filters.noOfWeeklyYTDWeeks = null;
          }
          queryData.filters.noOfWeeks = queryData.filters.noOfWeeklyYTDWeeks;
          delete queryData.filters.noOfWeeklyYTDWeeks;
          if (get(queryData, 'filters.symbols', []).length > 0) {
            delete queryData.filters.noOfWeeks;
          }
          return {
            query: commonSearchQuery(queryData),
            queryData: queryData,
          };
        default:
          return null;
      }

    case 'BUILD_YOUR_OWN':
      if (minInstCount || maxInstCount) {
        queryData.filters.instCount = {
          min: parseInt(minInstCount),
          max: parseInt(maxInstCount),
        };
      }
      if (minLastSale || maxLastSale) {
        queryData.filters.lastSale = {
          min: parseInt(minLastSale),
          max: parseInt(maxLastSale),
        };
      }

      // if (multiSector) {
      //   queryData.filters.multiSector = multiSector;
      // }
      if (symbol) {
        queryData.filters.symbols = symbol;
      }
      if (minInstCountChange) {
        queryData.filters.instCountChange = {
          min: parseInt(minInstCountChange),
          // max: parseInt(maxInstCountChange),
        };
      }

      if (minInstSharesChange) {
        queryData.filters.instSharesChange = {
          min: parseInt(minInstSharesChange),
          // max: parseInt(maxInstSharesChange),
        };
      }

      if (minYtd || maxYtd) {
        queryData.filters.ytd = {
          min: parseInt(minYtd),
          max: parseInt(maxYtd),
        };
      }

      if (minOneYrGrowth || maxOneYrGrowth) {
        queryData.filters.oneYrGrowth = {
          min: parseInt(minOneYrGrowth),
          max: parseInt(maxOneYrGrowth),
        };
      }

      if (minFiveYrGrowth || maxFiveYrGrowth) {
        queryData.filters.fiveYrGrowth = {
          min: parseInt(minFiveYrGrowth),
          max: parseInt(maxFiveYrGrowth),
        };
      }

      // if (minPrevInstCount || maxPrevInstCount) {
      //   queryData.filters.prevInstCount = {
      //     min: parseInt(minPrevInstCount),
      //     max: parseInt(maxPrevInstCount),
      //   };
      // }

      // if (minAum || maxAum) {
      //   queryData.filters.aum = {
      //     min: parseInt(minAum),
      //     max: parseInt(maxAum),
      //   };
      // }

      // if (minP52WkLow || maxP52WkLow) {
      //   queryData.filters.p52WkLow = {
      //     min: parseInt(minP52WkLow),
      //     max: parseInt(maxP52WkLow),
      //   };
      // }

      // if (minP52WkHigh || maxP52WkHigh) {
      //   queryData.filters.p52WkHigh = {
      //     min: parseInt(minP52WkHigh),
      //     max: parseInt(maxP52WkHigh),
      //   };
      // }

      // if (institutions) {
      //   queryData.filters.ciks = institutions;
      // }

      // if (minOwnership || maxOwnership) {
      //   queryData.filters.ownership = {
      //     min: parseInt(minOwnership),
      //     max: parseInt(maxOwnership),
      //   };
      // }

      // if (instCountDoubled) {
      //   queryData.filters.instCountDoubled = instCountDoubled;
      // }

      // if (instSharesDoubled) {
      //   queryData.filters.instSharesDoubled = instSharesDoubled;
      // }
      // if (status) {
      //   queryData.filters.status = status;
      // }
      // if (criterion) {
      //   queryData.filters.criterion = criterion;
      // }
      // if (noOfQuarters) {
      //   queryData.filters.noOfQuarters = noOfQuarters;
      // }

      // if (minMarketCap || maxMarketCap) {
      //   queryData.filters.marketCap = {
      //     min: parseInt(minMarketCap),
      //     max: parseInt(maxMarketCap),
      //   };
      // }

      // if (minVolume || maxVolume) {
      //   queryData.filters.volume = {
      //     min: parseInt(minVolume),
      //     max: parseInt(maxVolume),
      //   };
      // }

      // if (isSP) {
      //   queryData.filters.isSP = isSP;
      // }

      // if (p52WCurrentlyPotential) {
      //   queryData.filters.p52WCurrentlyPotential = p52WCurrentlyPotential;
      // }
      // if (p52WFuturePotential) {
      //   queryData.filters.p52WFuturePotential = p52WFuturePotential;
      // }

      // if (minPossibleGrowth || maxPossibleGrowth) {
      //   queryData.filters.expReturns = {
      //     min: parseInt(minPossibleGrowth),
      //     max: parseInt(maxPossibleGrowth),
      //   };
      // }
      // if (top10hedgeFunds) {
      //   queryData.filters.top10hedgeFunds = top10hedgeFunds;
      // }
      if (filterOptions.minPrice) {
        queryData.filters.minPrice = filterOptions.minPrice;
      }
      if (filterOptions.maxPrice) {
        queryData.filters.maxPrice = filterOptions.maxPrice;
      }
      if (filterOptions.minInstShares) {
        queryData.filters.minInstShares = filterOptions.minInstShares;
      }
      if (filterOptions.maxInstShares) {
        queryData.filters.maxInstShares = filterOptions.maxInstShares;
      }
      if (filterOptions.minAproxTxnValue) {
        queryData.filters.minAproxTxnValue = filterOptions.minAproxTxnValue;
      }
      if (filterOptions.minAproxInvValue) {
        queryData.filters.minAproxInvValue = filterOptions.minAproxInvValue;
      }
      if (filterOptions.maxAproxTxnValue) {
        queryData.filters.maxAproxTxnValue = filterOptions.maxAproxTxnValue;
      }
      if (filterOptions.multiSector) {
        queryData.filters.multiSector = filterOptions.multiSector;
      }
      if (filterOptions.excludeMultiSector) {
        queryData.filters.excludeMultiSector = filterOptions.excludeMultiSector;
      }
      if (filterOptions.multiIndustry) {
        queryData.filters.multiIndustry = filterOptions.multiIndustry;
      }
      if (filterOptions.symbols) {
        queryData.filters.symbols = filterOptions.symbols;
      }
      if (filterOptions.ytd) {
        queryData.filters.ytd = {
          ...queryData.filters.ytd,
          ...filterOptions.ytd,
        };
      }
      if (filterOptions.oneYrGrowth) {
        queryData.filters.oneYrGrowth = {
          ...queryData.filters.oneYrGrowth,
          ...filterOptions.oneYrGrowth,
        };
      }
      if (filterOptions.fiveYrGrowth) {
        queryData.filters.fiveYrGrowth = {
          ...queryData.filters.fiveYrGrowth,
          ...filterOptions.fiveYrGrowth,
        };
      }
      if (filterOptions.instCount) {
        queryData.filters.instCount = {
          ...queryData.filters.instCount,
          ...filterOptions.instCount,
        };
      }
      if (filterOptions.maxPossibleGrowth) {
        queryData.filters.maxPossibleGrowth = maxPossibleGrowth;
      }
      if (filterOptions.minPossibleGrowth) {
        queryData.filters.minPossibleGrowth = minPossibleGrowth;
      }
      if (filterOptions.maxRank) {
        queryData.filters.maxRank = maxRank;
      }
      if (filterOptions.minRank) {
        queryData.filters.minRank = minRank;
      }
      // queryData.filters = {
      //   ...queryData.filters,
      //   ...filterOptions,
      // };
      return {
        query: commonSearchQuery(queryData),
        queryData: queryData,
      };
    default:
      return null;
  }
};

export const getSearchSymbolTablesData = (
  rawData,
  symbol = '',
  sortDetail,
  theme,
  history,
  handleISRTOIIR,
  ISRTOStatusReport,
) => {
  const formattedData = [];
  console.log('raw:', rawData);
  const { tickerNews } = rawData;
  if (rawData['searchOpenSymbol']) {
    rawData = rawData['searchOpenSymbol'];
  }
  for (let key of Object.keys(rawData)) {
    console.log('key:', key);
    const sortObject = sortDetail.find((d) => d.key === key);
    switch (key) {
      case 'forecastedPrices':
        formattedData.push({
          type: 'table',
          title: `TODAY'S FORECAST FOR ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            null,
            null,
            null,
            null,
            theme,
          ),
          data: formatForecastedData(rawData[key].data, rawData[key].note),
          key: `${key}-${uuidv4()}`,
          index: 0,
        });
        break;
      case 'tickerNews':
        formattedData.push({
          type: 'news',
          title: `RECENT NEWS OF: ${symbol}`,
          data: tickerNews,
          key: `${key}-${uuidv4()}`,
          index: 1,
        });
        break;

      // case 'symbolAnalysis':
      //   formattedData.push({
      //     type: 'list',
      //     title: `STATISTICAL ANALYSIS REPORT OF: ${company}`,
      //     data: getSymbolAnalysisData(symbolAnalysis),
      //     key: `${key}-${uuidv4()}`,
      //     index: 1,
      //   });
      // break;
      case 'ownersInfo':
        formattedData.push({
          type: 'table',
          title: `Who Owns 10% Ownership for ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            true,
            sortObject
              ? [{ sortBy: sortObject.sortBy, sortOrder: sortObject.sortOrder }]
              : [],
            history,
            false,
            rawData[key].note ? rawData[key].note : null,
            'isr',
            null,
            null,
            theme,
            handleISRTOIIR,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 2,
          name: key,
          dataSource: rawData[key].source,
        });
        break;
      case 'latestStatus':
        formattedData.push({
          type: 'table',
          title: `Institution Counts for ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 3,
        });
        break;
      case 'instCountsByStatus':
        formattedData.push({
          type: 'table',
          title: `Counts by status for ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 4,
        });
        break;
      case 'dividendsInfo':
        formattedData.push({
          type: 'table',
          title: `Dividends History For ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            null,
            null,
            null,
            null,
            theme,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 5,
        });
        break;
      case 'priceHistory':
        formattedData.push({
          type: 'table',
          title: `Price History for ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 6,
        });
        break;
      case 'dailyPrices':
        formattedData.push({
          type: 'table',
          title: `10 Day Price Details For ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            null,
            null,
            null,
            null,
            theme,
          ),
          data: formatDataRow(rawData[key].data, theme),
          key: `${key}-${uuidv4()}`,
          index: 7,
        });
        break;
      case 'movingAvgs10Weeks':
        formattedData.push({
          type: 'table',
          title: `10 Moving Weeks Average Price Details For ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            null,
            null,
            null,
            null,
            theme,
          ),
          data: formatDataRow(rawData[key].data, theme),
          key: `${key}-${uuidv4()}`,
          index: 8,
        });
        break;
      case 'movingAvgs10Months':
        formattedData.push({
          type: 'table',
          title: `10 Moving Months Average Price Details For ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: formatDataRow(rawData[key].data, theme),
          key: `${key}-${uuidv4()}`,
          index: 9,
        });
        break;
      case 'financialData':
        formattedData.push({
          type: 'table',
          title: `Quarterly Financials of #${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: formatDataRow(rawData[key].data, theme),
          key: `${key}-${uuidv4()}`,
          index: 10,
          name: key,
        });
        break;
      case 'earningsData':
        formattedData.push({
          type: 'table',
          title: `Earnings Calendar of #${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: formatDataRow(rawData[key].data, theme),
          key: `${key}-${uuidv4()}`,
          index: 10,
          name: key,
        });
        break;
      case 'quarterlyData':
        formattedData.push({
          type: 'table',
          title: `Comparison by Quarterly Price, Institutions Counts and Institutional Shares For #${symbol} ( Current Price: ${currencyFormat(
            rawData['forecastedPrices']?.data[0]?.lastSale,
            false,
            false,
            false,
            false,
            true,
          )} )`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: formatDataRow(rawData[key].data, theme),
          key: `${key}-${uuidv4()}`,
          index: 11,
          name: key,
        });
        break;
      case 'statusCounts':
        formattedData.push({
          type: 'table',
          title: `SHARE ACTIVITY COUNT For ${symbol}`,
          summary: (rawData[key].data || []).filter(
            (d) => d.status === 'TOTAL',
          ),
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            history,
            false,
            rawData[key].note ? rawData[key].note : 'countStatus',
            'status',
            null,
            null,
            theme,
            null,
            null,
            symbol,
            null,
            ISRTOStatusReport,
          ),
          data: (rawData[key].data || []).filter((d) => d.status !== 'TOTAL'),
          key: `${key}-${uuidv4()}`,
          index: 12,
          name: key,
        });
        break;
      case 'top10InstShares':
        formattedData.push({
          type: 'table',
          title: `TOP-10-HEDGE FUNDS for ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) =>
                col.visible !== false && col.value && col.name !== 'inst_cik',
            ),
            true,
            sortObject
              ? [{ sortBy: sortObject.sortBy, sortOrder: sortObject.sortOrder }]
              : [],
            history,
            false,
            rawData[key].note ? rawData[key].note : null,
            'isr',
            null,
            null,
            theme,
            handleISRTOIIR,
          ),
          data: (rawData[key].data || []).filter(
            (d) => d.institutionName !== 'TOTAL',
          ),
          summary: getSummaryFilteredData(
            (rawData[key].data || []).filter(
              (d) => d.institutionName === 'TOTAL',
            ),
          ),
          key: `${key}-${uuidv4()}`,
          index: 13,
          name: key,
        });
        break;
      case 'recentSECShares':
        formattedData.push({
          type: 'table',
          title: `RECENT TRANSACTIONS (SEC-13D/G) REPORTED FOR ${symbol}`,
          total: getTotal(rawData[key]),
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) =>
                col.visible !== false && col.value && col.name !== 'inst_cik',
            ),
            true,
            sortObject
              ? [{ sortBy: sortObject.sortBy, sortOrder: sortObject.sortOrder }]
              : [],
            history,
            false,
            rawData[key].note ? rawData[key].note : null,
            'isr',
            null,
            null,
            theme,
            handleISRTOIIR,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 14,
          name: key,
        });
        break;
      case 'instShares':
        formattedData.push({
          type: 'table',
          title: `TOP 10 INSTITUTIONS OWNING FOR ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) =>
                col.visible !== false && col.value && col.name !== 'inst_cik',
            ),
            true,
            sortObject
              ? [{ sortBy: sortObject.sortBy, sortOrder: sortObject.sortOrder }]
              : [],
            history,
            false,
            rawData[key].note ? rawData[key].note : null,
            'isr',
            null,
            null,
            theme,
            handleISRTOIIR,
          ),
          data: (rawData[key].data || []).filter(
            (d) => d.institutionName !== 'TOTAL',
          ),
          summary: getSummaryFilteredData(
            (rawData[key].data || []).filter(
              (d) => d.institutionName === 'TOTAL',
            ),
          ),
          key: `${key}-${uuidv4()}`,
          index: 15,
          name: key,
        });
        break;
      case 'insiderTransactions':
        formattedData.push({
          type: 'table',
          title: `LATEST INSIDER TRANSACTIONS FOR ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) =>
                col.visible !== false && col.value && col.name !== 'inst_cik',
            ),
            true,
            sortObject
              ? [{ sortBy: sortObject.sortBy, sortOrder: sortObject.sortOrder }]
              : [],
            history,
            false,
            rawData[key].note ? rawData[key].note : null,
            'isr',
            null,
            null,
            theme,
            handleISRTOIIR,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 16,
          name: key,
        });
        break;
      case 'mutualFundQuarterlyShares':
        formattedData.push({
          type: 'table',
          title: `LATEST MUTUAL FUND TRANSACTIONS FOR ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) =>
                col.visible !== false && col.value && col.name !== 'inst_cik',
            ),
            true,
            sortObject
              ? [{ sortBy: sortObject.sortBy, sortOrder: sortObject.sortOrder }]
              : [],
            history,
            false,
            rawData[key].note ? rawData[key].note : null,
            'isr',
            null,
            null,
            theme,
            handleISRTOIIR,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          index: 17,
          name: key,
        });
        break;

      // case 'quarterlyCounts':
      //   formattedData.push({
      //     title: 'INSTITUTIONAL INVESTORS COUNT',
      //     columns: formatColumn(arrangeColumns(rawData[key].columns)),
      //     data: rawData[key].data,
      //     key: `${key}-${uuidv4()}`,
      //   });
      //   break;
      // case 'quarterlyShares':
      //   formattedData.push({
      //     title: 'INSTITUTIONAL INVESTORS SHARES',
      //     columns: formatColumn(arrangeColumns(rawData[key].columns)),
      //     data: rawData[key].data,
      //     key: `${key}-${uuidv4()}`,
      //   });
      //   break;
      // case 'instMfShares':
      //   formattedData.push({
      //     title:
      //       'TOTAL SHARES PICTURE FROM INSTITUTIONAL INVESTORS AS WELL AS TOP-10 MUTUAL FUNDS STAND POINT',
      //     columns: formatColumn(arrangeColumns(rawData[key].columns)),
      //     data: rawData[key].data,
      //     key: `${key}-${uuidv4()}`,
      //   });
      //   break;
      // case 'mfSharesDetails':
      //   formattedData.push({
      //     title: 'TOP-10-MUTUAL FUNDS INVESTED INTO',
      //     columns: formatColumn(arrangeColumns(rawData[key].columns)),
      //     data: rawData[key].data,
      //     key: `${key}-${uuidv4()}`,
      //   });
      //   break;

      // case 'allQuarterlyData':
      //   formattedData.push({
      //     title: 'THINKSABIO-RISHI',
      //     columns: formatColumn(arrangeColumns(rawData[key].columns)),
      //     data: rawData[key].data,
      //     key: `${key}-${uuidv4()}`,
      //   });
      //   break;
      default:
        break;
    }
  }
  console.log('formattedData:', formattedData);
  return formattedData.sort((a, b) => a.index - b.index); // hide tables for which data is empty
};

export const getCompareSymbolTablesData = (rawData, theme, history) => {
  const AllQuarterlyData = [];
  const QtlyIndividualHedgeShrs = [];

  for (let key of Object.keys(rawData)) {
    switch (key) {
      case 'allQuarterlyData':
        AllQuarterlyData.push(
          {
            title:
              'COMPARE YOUR STOCKS AGAINST INSTITUTIONAL INVESTORS AND LOOK AT POSSIBLE GROWTH%',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare1).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: rawData[key].data,
            key: `${key}-${uuidv4()}`,
            type: 'single',
          },
          {
            title: 'QUARTERLY INSTITUTIONAL SHARES COMPARISON',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare5).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: rawData[key].data,
            key: `${key}-${uuidv4()}`,
            type: 'single',
          },
          {
            title: 'QUARTERLY AVERAGE PRICE COMPARISON',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare7).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: rawData[key].data,
            key: `${key}-${uuidv4()}`,
            type: 'single',
          },
          {
            title: 'QUARTERLY INSTITUTIONAL COUNTS COMPARISON',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare2).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: rawData[key].data,
            key: `${key}-${uuidv4()}`,
            type: 'single',
          },
          {
            title: 'QUARTERLY TOP 10 HEDGE COUNTS COMPARISON',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare3).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: rawData[key].data,
            key: `${key}-${uuidv4()}`,
            type: 'single',
          },
          {
            title: 'QUARTERLY INSTITUTIONAL & TOP 10 HEDGE COUNTS COMPARISON',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare4).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: rawData[key].data,
            key: `${key}-${uuidv4()}`,
            type: 'single',
          },
          {
            title: 'TOP-10-HEDGE FUNDS INVESTED MATCHING AGAINST YOUR STOCKS',
            columns: formatColumn(
              filterColumns(rawData[key].columns, compare5).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            data: ['dummy'],
            key: `${key}-${uuidv4()}`,
            type: 'nested',
          },
        );
        break;
      case 'qtlyIndividualHedgeShrs':
        rawData[key].map((data, index) => {
          // eslint-disable-line
          QtlyIndividualHedgeShrs.push({
            title: 'TOP-10-HEDGE FUNDS INVESTED MATCHING AGAINST YOUR STOCKS',
            subTitle: `Rank=${index + 1} ( ${data.name} - ${data.symbol} )`,
            symbolTitle: `${data.name} - ${data.symbol}`,
            // columns: formatColumn(filterColumns(c.columns, compare6)),
            columns: formatColumn(
              filterColumns(data.columns, compare6).filter(
                (col) => col.visible !== false && col.value,
              ),
              false,
              [],
              history,
              false,
              rawData[key].note ? rawData[key].note : null,
              'compare',
              null,
              null,
              theme,
            ),
            index: index,
            data: data.data,
            key: `${key}-${uuidv4()}`,
            type: 'nested',
          });
        });

        break;
      default:
        break;
    }
  }
  return {
    qtlyIndividualHedgeShrs: QtlyIndividualHedgeShrs.filter(
      (tableData) => tableData.data.length > 0,
    ),
    allQuarterlyData: AllQuarterlyData.filter(
      (tableData) => tableData.data.length > 0,
    ),
  }; // hide tables for which data INDIVIDUAL_INSTITUTION_REPORT empty
};

export const getHeaders = ({
  reportSubType,
  reportType,
  goBack,
  isLoading,
  currentSearchCriteria,
  reportTitle,
  fetchReportName,
  fetchingName,
  breadcrumb,
  isFilterEmpty,
  handleFilterValueClick,
  handleReset,
  setShowMinerviniText,
  showMinerviniText,
  getLastQtrByIdx,
  getDisplayableQtr,
  selectedQuarter,
  history,
  setShowGraph,
  note,
  searchReportTitle,
}) => {
  if (reportType === 'THINKSABIO_DIAMONDS') {
    return (
      <DiamondReportHeader
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        isFilterEmpty={isFilterEmpty}
        handleFilterValueClick={handleFilterValueClick}
        handleReset={handleReset}
        setShowMinerviniText={setShowMinerviniText}
        showMinerviniText={showMinerviniText}
        getLastQtrByIdx={getLastQtrByIdx}
        getDisplayableQtr={getDisplayableQtr}
        selectedQuarter={selectedQuarter}
        history={history}
        setShowGraph={setShowGraph}
        searchReportTitle={searchReportTitle}
      />
    );
  }
  if (reportSubType === 'C52WKHL') {
    return (
      <Symbol52WeekLowReversal
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        isFilterEmpty={isFilterEmpty}
        handleFilterValueClick={handleFilterValueClick}
        handleReset={handleReset}
        history={history}
        note={note}
        searchReportTitle={searchReportTitle}
      />
    );
  }
  if (
    [
      'PRICE_HISTORY',
      'OPTION_ALL_INSTITUTION',
      'OPTION_BY_INSTITUTION',
      'INSIDER_DAILY_REPORT',
      'INSIDER_SYMBOL_SUMMARY',
      'INSIDER_DETAIL_SUMMARY_REPORT',
      'ALL_INSTITUTIONS',
      'TOP_10_HEDGE',
      'ALLOCATIONS',
      'BY_FUND_SYMBOL',
      'SOLD_OFF',
      'NEWLY_BOUGHT',
    ].includes(reportSubType) ||
    ['PRICE', 'BUILD_YOUR_OWN'].includes(reportType)
  ) {
    return (
      <SimpleHeader
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        isFilterEmpty={isFilterEmpty}
        handleFilterValueClick={handleFilterValueClick}
        handleReset={handleReset}
        history={history}
        note={note}
        searchReportTitle={searchReportTitle}
      />
    );
  }

  return null;
};

export const formatLargeNumbers = (n: number) => {
  if (n === 0) return '0';

  const sign = n < 0 ? '-' : '';
  const absNumber = Math.abs(n);

  const denominations = [
    { value: 1e12, symbol: 'T' }, // Trillions
    { value: 1e9, symbol: 'B' }, // Billions
    { value: 1e6, symbol: 'M' }, // Millions
    { value: 1e3, symbol: 'K' }, // Thousands
    { value: 1, symbol: '' }, // Base case (no abbreviation)
  ];

  for (const { value, symbol } of denominations) {
    if (absNumber >= value) {
      const formattedNumber = (absNumber / value).toFixed(symbol ? 1 : 0);
      return `${sign}${parseFloat(formattedNumber).toString()}${symbol}`;
    }
  }

  return `${sign}${n}`;
};

export const mapStockData = (rawData) => {
  console.log(rawData);
  return rawData.map((stock) => {
    const mappedStock = {
      symbol: stock.find((i) => i.key === 'symbol')?.value || '',
      lastQtr2Count: stock.find((i) => i.key === 'lastQtr2Count')?.value || '',
      lastQtr1Count: stock.find((i) => i.key === 'lastQtr1Count')?.value || '',
      lastQtr2Shares:
        stock.find((i) => i.key === 'lastQtr2Shares')?.value || '',
      lastQtr1Shares:
        stock.find((i) => i.key === 'lastQtr1Shares')?.value || '',
      lastQtr2Volume:
        stock.find((i) => i.key === 'lastQtr2Volume')?.value || '',
      lastQtr1Volume:
        stock.find((i) => i.key === 'lastQtr1Volume')?.value || '',
      lastSale: stock.find((i) => i.key === 'lastSale')?.value || '',
      price_on_feb14:
        stock.find((i) => i.key === 'price_on_feb14')?.value || '',
      price_on_may14:
        stock.find((i) => i.key === 'price_on_may14')?.value || '',
      price_on_aug14:
        stock.find((i) => i.key === 'price_on_aug14')?.value || '',
      price_on_nov14:
        stock.find((i) => i.key === 'price_on_nov14')?.value || '',
      returnsPerc: stock.find((i) => i.key === 'returnsPerc')?.value || '',
      c52WkLow: stock.find((i) => i.key === 'c52WkLow')?.value || '',
      c52WkHigh: stock.find((i) => i.key === 'c52WkHigh')?.value || '',
      lastQtr2Avg: stock.find((i) => i.key === 'lastQtr2Avg')?.value || '',
      lastQtr1Avg: stock.find((i) => i.key === 'lastQtr1Avg')?.value || '',
      lastQtr1ApproxInvestedValue:
        stock.find((i) => i.key === 'lastQtr1ApproxInvestedValue')?.value || '',
    };

    return mappedStock;
  });
};

export const getGraphData = (TData, columnData, graphType) => {
  console.log('TDATA here', TData);
  if (graphType === 'Inst Shares') {
    const transformData = (data) => {
      return data.map((group) => {
        const resultObj = {};
        group.forEach((item) => {
          switch (item.key) {
            case 'name':
              resultObj.name = item.value;
              break;
            case 'symbol':
              resultObj.symbol = item.value;
              break;
            case 'lastQtr1Shares':
              resultObj.lastQtr1Shares = item.value;
              break;
            case 'lastQtr2Shares':
              resultObj.lastQtr2Shares = item.value;
              break;
            default:
              break;
          }
        });
        return resultObj;
      });
    };
    const transformedData = transformData(TData);
    return transformedData;
  }
  if (graphType === 'Total Shares') {
    const transformData = (data) => {
      return data.map((group) => {
        const resultObj = {};
        group.forEach((item) => {
          switch (item.key) {
            case 'name':
              resultObj.name = item.value;
              break;
            case 'symbol':
              resultObj.symbol = item.value;
              break;
            case 'totalLastQtr1Shares':
              resultObj.totalLastQtr1Shares = item.value;
              break;
            case 'totalLastQtr2Shares':
              resultObj.totalLastQtr2Shares = item.value;
              break;
            default:
              break;
          }
        });
        return resultObj;
      });
    };
    const transformedData = transformData(TData);
    return transformedData;
  }
  if (graphType === 'Inst Counts') {
    const transformData = (data) => {
      return data.map((group) => {
        const resultObj = {};
        group.forEach((item) => {
          switch (item.key) {
            case 'name':
              resultObj.name = item.value;
              break;
            case 'symbol':
              resultObj.symbol = item.value;
              break;
            case 'lastQtr1Count':
              resultObj.lastQtr1Count = item.value;
              break;
            case 'lastQtr2Count':
              resultObj.lastQtr2Count = item.value;
              break;
            // Add more cases if you want to extract more fields.
            default:
              break;
          }
        });
        return resultObj;
      });
    };
    const transformedData = transformData(TData);
    return transformedData;
  }
  return {};
};

export const MarkMinerviniReportText = ({ showMinerviniText }) => {
  return (
    <div
      hidden={!showMinerviniText}
      style={{
        textAlign: 'left',
        width: 800,
        fontSize: '15px',
        color: 'var(--whitecolor)',
      }}
    >
      <br />
      <span style={{ fontSize: '15px' }}>
        Have you ever been fearful of losing money in the stock market? Well, we
        can help you overcome that with proven tactics from one of America’s
        best investors. Mark Minervini is one of the most successful investors
        of the last 30 years, consistently outperforms the stock market, and is
        a two time winner of the U.S. Investing Championship.
      </span>
      <br />
      <br />
      <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
        Here are his criteria for investing in a stock:
      </span>{' '}
      <br />
      <br />
      <ul>
        <li style={{ fontSize: '15px', height: '1px' }}>
          The current stock price is trading above the 50 Day Moving Average
          (DMA), as well as the 150 DMA and 200 DMA
        </li>
        <br />{' '}
        <li style={{ fontSize: '15px', height: '1px' }}>
          The 50 DMA is above the 150 DMA, and the 150 DMA is above the 200 DMA
        </li>
        <br />
        <li style={{ fontSize: '15px', height: '1px' }}>
          The 200 DMA is trending up for at least 1 month (22 active trading
          days)
        </li>
        <br />
        <li style={{ fontSize: '15px', height: '25px' }}>
          {' '}
          The current stock price is 25% above the 52 week low (the lowest price
          the stock has been in the past year) and within 25% of the 52 week
          high (the highest price the stock has been in the past year
        </li>
        <br />
        <li style={{ fontSize: '15px', height: '1px' }}>
          {' '}
          The stock has a Relative Strength Index (RSI) of 70
        </li>
      </ul>
      <br />
      <p style={{ fontSize: '15px' }}>
        These criteria all measure the strength and upward trend of a stock.
        Below, you’ll find the stocks that meet these criteria so you can
        determine if they’re a good investment for you.
      </p>
      <br />
      <div style={{ fontSize: '15px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Note:</span>{' '}
        <br />
        <br /> * Daily moving average measures the price of a stock over a given
        period of time.
        <br /> * Relative strength index measures the current strength of a
        stock based on its recent closing prices compared to its historical
        averages.
        <br />* The percentages in the report are calculated relative to the
        current price.
        <br />* The sorting order in the below columns is determined by the
        values presented as percentages.
      </div>
    </div>
  );
};
