import React from 'react';

import { BarChartOutlined } from '@ant-design/icons';

import BreadcrumbAndGoBack from '../BreadcrumbAndGoBack';
import CommonHeader from '../CommonHeader';
import { getDiamondReportExplainationText } from '../SearchReport.constants';
import styles from '../style.module.scss';

const DiamondReportHeader = (props) => {
  const {
    reportType,
    reportSubType,
    goBack,
    isLoading,
    currentSearchCriteria,
    reportTitle,
    fetchReportName,
    fetchingName,
    breadcrumb,
    isFilterEmpty,
    handleFilterValueClick,
    handleReset,
    setShowMinerviniText,
    showMinerviniText,
    getLastQtrByIdx,
    getDisplayableQtr,
    selectedQuarter,
    history,
    setShowGraph,
    searchReportTitle,
  } = props;
  return (
    <>
      <BreadcrumbAndGoBack
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        history={history}
      />
      <div className={styles.symbolSearchContentBox}>
        <div
          className={styles.titleContainer}
          style={{
            marginBottom: '12px',
          }}
        >
          <div
            className={styles.titleContent}
            style={{
              gap: '12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <h3
                style={{
                  lineHeight: '20px',
                }}
              >
                {searchReportTitle}
              </h3>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                }}
              >
                {reportSubType === 'THINKSABIO_DIAMONDS'
                  ? 'Quarterly % Shares Changes Surpassing the 50% Mark'
                  : reportSubType === 'THINKSABIO_DIAMONDS_PV'
                  ? 'Unveiling the Quarterly Price and Volume Winners from Previous to latest quarter in our report'
                  : 'Unmasking the Surge in Counts, Shares, Approx. Invested value,Price, and Volume (CSAPV) for Selected Stocks'}
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                }}
              >
                {reportType === 'THINKSABIO_DIAMONDS'
                  ? 'For Graphical Representation Click the Graph Icon (Data is Based on the Report Displayed Below)'
                  : ''}
              </p>
              <button
                style={{
                  backgroundColor: '#414a4c',
                  alignItems: 'center',
                  width: '200px',
                }}
                onClick={() => setShowMinerviniText(!showMinerviniText)}
              >
                {showMinerviniText
                  ? 'Click here to Hide Text'
                  : 'Click here to learn more'}
              </button>
              {showMinerviniText ? (
                <div
                  style={{
                    textAlign: 'left',
                    width: 800,
                    fontSize: '15px',
                    color: 'var(--whitecolor)',
                  }}
                >
                  {getDiamondReportExplainationText(
                    reportSubType,
                    getDisplayableQtr,
                    selectedQuarter,
                    getLastQtrByIdx,
                  )}
                </div>
              ) : null}
            </div>
            {!isLoading && reportType === 'THINKSABIO_DIAMONDS' && (
              <span
                onClick={() => setShowGraph(true)}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <BarChartOutlined
                  style={{ color: '#8884d8', fontSize: '36px' }}
                />
              </span>
            )}
          </div>
        </div>

        {isFilterEmpty.length > 0 && (
          <div className={styles.filterValueBox}>
            <CommonHeader
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DiamondReportHeader;
